import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

interface Props {
    title?: string;
}

const Wrapper = styled.div`
    height: 10vh;
    width: 100%;
    background: rgba(17, 17, 17, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7% 0 7%;
    position: fixed;
    backdrop-filter: blur(7px);
    z-index: 10001;
`;

const Logo = styled.a`
  font-size: 1.25em;
  text-align: center;
  color: #FFF;
  font-weight: 600;
`;

const Row = styled.div`
    flex-direction: row;
    align-items: center;
    gap: 1.5em;
`

const Link = styled.a`
    font-size: 1em;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 400;
`

const Button = styled.button<{ $background?: string }>`
    height: 34px;
    background: ${props => props.$background || '#7D71EA'};
    border-radius: 0.5vh;
    aspect-ratio: 145 / 46;
    font-size: 1em;
    outline: none;
    border: none;
    color: #FFF;
    font-weight: 500;
`

const Header: React.FC<Props> = ({
    title = 'Aminos.Dev',
}) => {

    const user = useAuth();

    const logOut = async () => {
        await signOut(auth);
    }

    return (
        <div className='flex flex-row fixed items-center justify-between px-[2rem] md:px-[5rem] lg:px-[7rem] xl:px-[9rem] 2xl:px-[9rem] h-[5rem] bg-zinc-900 w-screen z-50 shadow-base'>
            <a className='text-slate-50 font-bold text-xl' href='/'>{title}</a>
            <div className='flex flex-row items-center gap-8'>
                <a className='text-slate-50 font-medium' href='/portfolio'>Portfolio</a>
                <a className='text-slate-50 font-medium' href='/contact'>Contact</a>
                <a className='text-slate-50 font-medium' href='/about'>About</a>
                <button className='font-medium text-slate-50 bg-red-500 rounded px-7 py-1 shadow-sm' onClick={() => { user ? logOut() : window.location.href = '/login' }}>{user ? 'Logout' : 'Login'}</button>
            </div>
        </div>
        // <Wrapper>
        //     <Logo href='/'>{title}</Logo>
        //     <Row>
        //         {/* <Link href='/pricing'>Pricing</Link> */}
        //         <Link href='/portfolio'>Portfolio</Link>
        //         <Link href='/contact'>Contact</Link>
        //         <Link href='/about'>About</Link>
        //         <Button $background={user ? '#EC2F4B' : '#7D71EA'} onClick={() => { user ? logOut() : window.location.href = '/login' }}>{user ? 'Logout' : 'Login'}</Button>
        //     </Row>
        // </Wrapper>
    )
}

export default Header;
import styled, { keyframes } from "styled-components"

export const TitleHeader = styled.h1<{ $textalign?: string }>`
    font-size: 4.875em;
    font-weight: 600;
    color: #FFF;
    text-align: ${props => props.$textalign || 'auto'};

    @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 3.5em;
    }
`

export const SubHeader = styled(TitleHeader)`
    font-size: 1.25em;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);

    @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 1em;
    }
`

export const GradientHeader = styled.span`
  font-size: 5.675em;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -2px;
  background: linear-gradient(104deg, #009FFF 0%, #EC2F4B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
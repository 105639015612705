import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components';
import Header from '../../components/Header';
import ProjectPreview from '../../components/ProjectPreview';
import Footer from '../../components/Footer';
import { handlePurchase } from '../../server';
import { getProjects } from '../../firebase';
import { ProjectObj } from '../../types/project';
import { fadeIn } from '../../styles';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background: #111;
  width: 100vw;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7% 7vw 3% 7vw;
    min-height: 100vh;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards; /* Use the defined keyframes *

    @media (max-width: 767px) {
        padding: calc(10vh + 2vh) 5vw 3vh 5vw;
    }
`

// Row turns into a column on mobile devices
const FlexRow = styled.div<{ $margintop?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: ${props => props.$margintop || 'none'};

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 30px;
    }
`

const useCachedProjects = () => {
    const cachedProjects = localStorage.getItem('projects');
    const initialProjects = cachedProjects ? JSON.parse(cachedProjects) : [] as ProjectObj[];
    const [projects, setProjects] = useState(initialProjects);

    const loadProjects = async () => {
        const fetchedProjects = await getProjects();
        if (fetchedProjects) {
            setProjects(fetchedProjects);
            localStorage.setItem('projects', JSON.stringify(fetchedProjects));
        }
    };

    useEffect(() => {
        loadProjects();
    }, []);

    return [projects, loadProjects];
};

const Portfolio = () => {

    const [projects, loadProjects] = useCachedProjects();

    const rows = useMemo(() => {
        return Array.from({ length: Math.ceil(projects.length / 3) }, (_, rowIndex) => projects.slice(rowIndex * 3, rowIndex * 3 + 3));
    }, [projects])

    useEffect(() => {
        const handleBeforeUnload = () => {
            loadProjects();
        };

        // Attach the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener when the component is unmounted
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);

    }, []);

    return (
        <Container>
            <Header title='Aminos.Dev.Portfolio' />
            <Wrapper>
                {
                    rows.map((row, index) => (
                        <FlexRow $margintop={index !== 0 ? '32px' : 'none'}>
                            {row.map((item: ProjectObj, itemIndex: number) => (
                                <ProjectPreview {...item} onPurchase={() => { handlePurchase(item.appId) }} onView={() => { window.location.href = `project/${item.projectId}` }} />
                            ))}
                        </FlexRow>
                    ))
                }
            </Wrapper>
            <Footer />
        </Container>
    )
}

export default Portfolio;
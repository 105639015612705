import { useEffect, useState } from "react";
import { getProjects } from "../firebase";
import { ProjectObj } from "../types/project";

export const useCachedProjects = () => {
    const cachedProjects = localStorage.getItem('projects');
    const initialProjects = cachedProjects ? JSON.parse(cachedProjects) : [] as ProjectObj[];
    const [projects, setProjects] = useState(initialProjects);

    const loadProjects = async () => {
        const fetchedProjects = await getProjects();
        if (fetchedProjects) {
            setProjects(fetchedProjects);
            localStorage.setItem('projects', JSON.stringify(fetchedProjects));
        }
    };

    useEffect(() => {
        loadProjects();
    }, []);

    return [projects, loadProjects];
};
export const Bridge3D = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M24 5.33325H28" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.3333 27.9999C14.0697 27.9999 14.6667 27.403 14.6667 26.6666C14.6667 25.9302 14.0697 25.3333 13.3333 25.3333C12.597 25.3333 12 25.9302 12 26.6666C12 27.403 12.597 27.9999 13.3333 27.9999Z" fill="black" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.6666 6.66667C19.403 6.66667 19.9999 6.06971 19.9999 5.33333C19.9999 4.59696 19.403 4 18.6666 4C17.9302 4 17.3333 4.59696 17.3333 5.33333C17.3333 6.06971 17.9302 6.66667 18.6666 6.66667Z" fill="black" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.3333 26.6666C13.3333 26.6666 21.9999 23.3333 15.9999 15.9999C9.99992 8.66659 18.6666 5.33325 18.6666 5.33325" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4 26.6667H8" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const WebDev = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M26.6668 16.0001V7.66479C26.6668 7.45261 26.5826 7.24914 26.4326 7.0991L22.2344 2.90107C22.0844 2.75103 21.881 2.66675 21.6688 2.66675H6.1335C5.69167 2.66675 5.3335 3.02492 5.3335 3.46675V28.5334C5.3335 28.9753 5.69167 29.3334 6.1335 29.3334H14.6668" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.6665 13.3333H21.3332M10.6665 8H15.9998M10.6665 18.6667H14.6665" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.7406 22.8178L22.9528 20.2475C23.1084 19.9175 23.558 19.9175 23.7136 20.2475L24.9257 22.8178L27.6365 23.2324C27.9844 23.2856 28.1229 23.7331 27.8712 23.9898L25.91 25.989L26.3728 28.8134C26.4322 29.176 26.0685 29.4527 25.7573 29.2814L23.3332 27.9471L20.909 29.2814C20.5978 29.4527 20.2341 29.176 20.2936 28.8134L20.7564 25.989L18.7952 23.9898C18.5434 23.7331 18.682 23.2856 19.0298 23.2324L21.7406 22.8178Z" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.3335 2.66675V7.20008C21.3335 7.64191 21.6916 8.00008 22.1335 8.00008H26.6668" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const MobileDev = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M16 25.3466L16.0133 25.3318" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M24 24V28.5333C24 28.9752 23.6419 29.3333 23.2 29.3333H8.8C8.35817 29.3333 8 28.9752 8 28.5333V24" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" />
        <path d="M24 8.00008V3.46675C24 3.02492 23.6419 2.66675 23.2 2.66675H8.8C8.35817 2.66675 8 3.02492 8 3.46675V8.00008" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" />
        <path d="M20.6665 11.3333L25.3332 15.9999L20.6665 20.6666" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.3332 11.3333L6.6665 15.9999L11.3332 20.6666" stroke="#7D71EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
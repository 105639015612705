import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import BasePageContainer from '../../components/BasePageContainer';
import Header from '../../components/Header';
import { SubHeader, GradientHeader, fadeIn } from '../../styles';
import CustomText from '../../components/CustomText';
import { useParams } from 'react-router-dom';
import projectArray, { FlowObj } from '../../data/projects';
import { useCachedProjects } from '../../hooks/useCachedProjects';
import { ProjectObj, Specification } from '../../types/project';

const Container = styled(BasePageContainer)`
    background: #111;
    min-height: 100vh;
    width: 100vw;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10% 22% 3% 22%;
    box-sixing: border-box;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards;

    @media (min-width: 1024px) and (max-width: 1439px) {
        padding: 7% 22% 3% 22%;
    }
`

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2vh;
`

const Image = styled.img`
    border-radius: 1vh;
    margin-top: 2vh;
`

const TitleHeader = styled(GradientHeader)`
    text-align: center;
`

const SpecRow = ({
    label = '',
    value = ''
}) => {
    return (
        <TableRow>
            <CustomText fontWeight='400' fontSize='1em'>{label}</CustomText>
            <CustomText fontSize='0.875em'>{value}</CustomText>
        </TableRow>
    )
}

const Project = () => {

    const { projectId = 'mirax' } = useParams();

    const [projects, loadProjects] = useCachedProjects();

    const data = useMemo(() => {
        return projects.find((item: ProjectObj) => item.projectId === projectId) || FlowObj;
    }, [projectId, projects]);

    const { title, description, overview, features, technologies, devImages, specDescription, specifications } = data;

    useEffect(() => {
        const handleBeforeUnload = () => {
            loadProjects();
        };

        // Attach the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener when the component is unmounted
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);

    }, []);

    return (
        <Container>
            <Header title='Aminos.Dev.Project' />

            <Wrapper>
                <TitleHeader>{title}</TitleHeader>
                <SubHeader $textalign='center'>{description}</SubHeader>

                <CustomText fontWeight='400' fontSize='1.5em' marginTop='15vh'>Overview</CustomText>
                <CustomText color='rgba(255, 255, 255, 0.9)' lineHeight='150%' marginTop='1.5vh'>{overview}</CustomText>
                <CustomText fontWeight='400' fontSize='1.5em' marginTop='5vh'>Features</CustomText>
                <CustomText color='rgba(255, 255, 255, 0.9)' lineHeight='150%' marginTop='1.5vh'>{features}</CustomText>
                <CustomText fontWeight='400' fontSize='1.5em' marginTop='5vh'>Technologies used</CustomText>
                <CustomText color='rgba(255, 255, 255, 0.9)' lineHeight='150%' marginTop='1.5vh'>{technologies.map((g: string, index: number) => `${g} ${(index + 1) === technologies.length ? '' : ' | '}`)}</CustomText>
                {
                    devImages && devImages.map((img: string) => (
                        <Image src={img} />
                    ))
                }
                <CustomText fontWeight='400' fontSize='1.5em' marginTop='5vh'>Specifications</CustomText>
                <CustomText color='rgba(255, 255, 255, 0.9)' lineHeight='150%' marginTop='1.5vh'>{specDescription}</CustomText>

                {
                    specifications.map((g: Specification) => (
                        <SpecRow {...g} />
                    ))
                }

            </Wrapper>
        </Container>
    )
}

export default Project;
import React from 'react';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import Pricing from './screens/Pricing';
import Portfolio from './screens/Portfolio';
import Project from './screens/Project';
import Login from './screens/Login';
import Signup from './screens/Signup';
import Contact from './screens/Contact';
import { AuthProvider } from './AuthContext';
import About from './screens/About';

export default class App extends React.Component {

  render() {
    return (
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='' element={<Home />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/project/:projectId' element={<Project />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
          </Routes>
        </Router>
      </AuthProvider>
    )
  }
}
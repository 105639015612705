import styled from 'styled-components';
import FlexRow from '../../components/FlexRow';
import PricingOption from '../../components/PricingOption';
import { PricingHeader, PricingSubHeader } from '../Pricing';
import { useEffect, useRef } from 'react';
import { fadeIn } from '../../styles';
import { useAuth } from '../../AuthContext';

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background: #111;
    min-height: 100vh;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    padding: 10% 0 5% 0;

    @media (min-width: 1024px) and (max-width: 1439px) {
        padding: 7% 0 3% 0;
    }

    @media (max-width: 767px) {
        padding: 15% 0 3% 0;
    }
`

const FlexColumn = styled.div<{ gap?: string; alignItems?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap || 'none'};
    align-items: ${props => props.alignItems || 'none'};
`

const Row = styled(FlexRow)`
    @media (max-width: 767px) {
        flex-direction: column;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards;
`

const WebsitePricing = () => {

    const user = useAuth();

    const handleOnboardEvent = async () => {
        if (user) {
            // User is signed in so continue to overview page
        } else {
            // Make user login or signup
            window.location.href = '/login'
        }
    }

    return (
        <Container>
            <Wrapper>
                <FlexColumn alignItems='center'>
                    <PricingHeader>Website Pricing</PricingHeader>
                    <PricingSubHeader>Invest in excellence: Explore our website pricing plans for every need</PricingSubHeader>
                </FlexColumn>

                <Row $gap='30px'>
                    <PricingOption onButtonPress={() => { handleOnboardEvent() }} packageType='Standard' price='$249' description='Simple website to showcase your business' features={['Pay-once, keep forever', 'Mobile friendly', 'Free hosting', 'Unlimited support']} />
                    <PricingOption onButtonPress={() => { handleOnboardEvent() }} premium packageType='Premium' price='$449' description='Advanced website to showcase your business & offer online bookings' features={['Pay-once, keep forever', 'Mobile friendly', 'Free hosting', 'Unlimited support']} />
                    <PricingOption onButtonPress={() => { window.location.href = '/contact' }} packageType='Custom' price='Contact Us' description='Work with our team to develop a truly customized advanced website' features={['Pay-once, keep forever', 'Mobile friendly', 'Free hosting', 'Unlimited support']} />
                </Row>
            </Wrapper>
        </Container>
    )
}

export default WebsitePricing;
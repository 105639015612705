import React from 'react'
import styled from 'styled-components';
import PricingOption from '../../components/PricingOption';
import FlexRow from '../../components/FlexRow';
import { PricingHeader, PricingSubHeader } from '../Pricing';
import { fadeIn } from '../../styles';

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background: #111;
    min-height: 100vh;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    padding: 10% 0 5% 0;

    @media (min-width: 1024px) and (max-width: 1439px) {
        padding: 7% 0 3% 0;
    }

    @media (max-width: 767px) {
        padding: 12% 0 3% 0;
    }
`

const FlexColumn = styled.div<{ gap?: string; alignItems?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap || 'none'};
    align-items: ${props => props.alignItems || 'none'};
`

const Row = styled(FlexRow)`
    @media (max-width: 767px) {
        flex-direction: column;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards;
`

const MobilePricing = () => {
    return (
        <Container>
            <Wrapper>
                <FlexColumn alignItems='center'>
                    <PricingHeader>Mobile App Pricing</PricingHeader>
                    <PricingSubHeader>Invest in excellence: Explore our mobile app pricing plans for every need</PricingSubHeader>
                </FlexColumn>

                <Row $gap='30px'>
                    <PricingOption packageType='Standard' price='$249' description='Unlimited commercial license, use however you like' features={['Pay-once, keep forever', 'Complete source code', 'Updates whenever available', 'Unlimited support']} />
                    <PricingOption premium packageType='Premium' price='$449' description='Unlimited access to all mobile apps, use however you like' features={['Pay-once, keep forever', 'Complete source code', 'Updates whenever available', 'Unlimited support']} />
                    <PricingOption packageType='Custom' price='Contact Us' description='Develop your own custom mobile app with our guidance' features={['Custom design', 'Complete source code', 'Updates whenever available', 'Unlimited support']} />
                </Row>
            </Wrapper>
        </Container>
    )
}

export default MobilePricing;
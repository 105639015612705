import styled from 'styled-components';
import WebsitePricing from '../WebsitePricing';
import Header from '../../components/Header';
import MobilePricing from '../MobilePricing';
import Footer from '../../components/Footer';

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
`

export const PricingHeader = styled.h1`
    font-size: 4.875em;
    font-weight: 600;
    color: #FFF;

    @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 3.5em;
    }

    @media (max-width: 767px) {
        font-size: 2.25em;
        text-align: center;
    }
`

export const PricingSubHeader = styled.h3`
    font-size: 1.25em;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);

    @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 1em;
    }

    @media (max-width: 767px) {
        font-size: 0.875em;
        text-align: center;
    }
`

const Pricing = () => {
    return (
        <Container>
            <Header />
            <WebsitePricing />
            <MobilePricing />
            <Footer />
        </Container>
    )
}

export default Pricing;
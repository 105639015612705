import styled from 'styled-components';

const CustomText = styled.span< {
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    lineHeight?: string;
    marginTop?: string;
    textAlign?: string;
}>`
   font-size: ${props => props.fontSize || '1em'}; 
   font-weight: ${props => props.fontWeight || '300'}; 
   color: ${props => props.color || '#FFF'};
   line-height: ${props => props.lineHeight || 'auto'};
   margin-top: ${props => props.marginTop || 'none'};
   text-align: ${props => props.textAlign || 'initial'};
`

export default CustomText;
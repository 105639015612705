import styled from 'styled-components';

interface Props {
    label: string;
    $background?: string;
    $margintop?: string;
    $height?: string;
    glow?: boolean;
}

const Button = styled.button <Omit<Props, 'label'>> `
    height: ${props => props.$height || '56px'};
    border-radius: 12px;
    background: ${props => props.$background || '#7D71EA'};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: ${props => props.glow ? '0px 4px 52px 12px rgba(125, 113, 234, 0.25)' : 'none'};
    margin-top: ${props => props.$margintop ? props.$margintop : 'none'};
    font-size: 1.05em;
    font-weight: 400;
    color: #FFF;

    aspect-ratio: 18 / 6;

    @media (min-width: 1024px) and (max-width: 1439px) {
        height: 7.5vh;
    }
`;

export default Button;
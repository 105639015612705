import { ProjectObj } from "../types/project";

export const FlowObj: ProjectObj = {
    title: 'Flow - Habit Tracker',
    description: 'Elevate productivity with a simple interface to create, manage, and track habits. Personalize, get insights, and stay motivated on your journey!',
    image: 'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fepoxy_preview.png?alt=media&token=22b6477d-69be-47a1-912d-4a2a63a8df12',
    overview: `Introducing Flow - Habit Tracker, a revolutionary app designed for those committed to cultivating effective habits. With a user-friendly interface, Flow enables you to effortlessly create, manage, and track your habits. Witness your progress at a glance, with a consolidated overview displaying weekly and overall achievements. Customize each habit with unique titles and descriptions, adding a personal touch to your journey.

    Stay motivated with our informative feed, offering tips and facts. Explore dedicated habit pages, complete with monthly calendars showcasing completion patterns. Learn the science of habits through free lessons and gain insights from habit statistics. Receive daily motivation and reminders tailored to your habits.`,
    features: [`Flow revolutionizes habit-building with its comprehensive features. Seamlessly create and customize habits with a user-friendly interface. Track progress effortlessly with detailed statistics, offering valuable insights into your journey. Receive daily motivational tips and timely reminders for consistent habit development. The app's sleek design enhances user experience, allowing for easy editing of habit details. Embrace a learning journey with dedicated pages, offering lessons on habit formation. Personalize your experience further by customizing the app's graphical features to match your style. Flow - where simplicity meets effectiveness, making habit development an engaging and rewarding experience.`],
    devImages: [
        'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fepoxy_promotional.png?alt=media&token=3b4de1cd-3eef-49d2-b987-11ebe5fbe252',
        'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fepoxy_preview.png?alt=media&token=22b6477d-69be-47a1-912d-4a2a63a8df12'
    ],
    technologies: ['React-Native', 'React', 'Firebase'],
    specDescription: 'The source code is readily accessible for Android users. However, for those seeking the iOS version, customization of the source code and its packages is necessary to ensure optimal functionality on iOS devices. Additionally, the app must be built on an Apple computer, a prerequisite applicable to all iOS applications.',
    specifications: [
        {
            label: 'React-Native Version',
            value: '0.72.3'
        },
        {
            label: 'React',
            value: '18.2.0'
        },
        {
            label: 'Compatible Mobile OS',
            value: 'Android only'
        },
        {
            label: 'Orientation',
            value: 'Portrait only'
        },
    ],
    projectId: 'flow',
    appId: 'AM_0001',
}

export const MiraxObj: ProjectObj = {
    title: 'Mirax - Meditation',
    description: 'Cultivate calmness with guided sessions, serene tunes, and tranquil ambiance. Your journey to mindfulness begins here. (Currently In Development)',
    image: 'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fmirax_preview.png?alt=media&token=fb20f918-a243-4414-82a1-7df46091fb60',
    overview: `Mirax-Meditation is a cutting-edge mental health app designed to guide users on a transformative journey towards inner peace and well-being. With a user-friendly interface and a diverse range of meditation sessions, Mirax-Meditation offers personalized mindfulness experiences to reduce stress, anxiety, and enhance overall mental health. The app integrates soothing audiovisual elements, expertly crafted meditation programs, and customizable features to cater to individual needs. From quick stress-relief sessions to comprehensive mindfulness programs, Mirax-Meditation empowers users to cultivate a sense of calm, balance, and resilience in their daily lives. Elevate your mental health journey with Mirax-Meditation, where tranquility meets technology.`,
    features: [`Mirax-Meditation is a versatile mental health app, offering guided and timed breathing exercises for tailored relaxation. Users can choose from customizable timed meditation sessions, fitting into any schedule. The app caters to individual preferences with its custom lessons, allowing a personalized approach to mental wellness. Mirax-Meditation seamlessly combines expert guidance with user flexibility for a comprehensive and concise meditation experience.`],
    devImages: [
        'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fmirax_dev_image1.png?alt=media&token=e61b2ffb-88ef-4590-bf91-cb02f6b0a50b',
        'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fmirax_promotional.png?alt=media&token=1ae7b806-7ff5-4efd-b5ca-fb84b586c598',
    ],
    technologies: ['React-Native', 'React', 'Firebase Analytics'],
    specDescription: 'The source code is readily accessible for Android users. However, for those seeking the iOS version, customization of the source code and its packages is necessary to ensure optimal functionality on iOS devices. Additionally, the app must be built on an Apple computer, a prerequisite applicable to all iOS applications.',
    specifications: [
        {
            label: 'React-Native Version',
            value: '0.72.3'
        },
        {
            label: 'React',
            value: '18.2.0'
        },
        {
            label: 'Compatible Mobile OS',
            value: 'Android only'
        },
        {
            label: 'Orientation',
            value: 'Portrait only'
        },
    ],
    projectId: 'mirax',
    appId: 'AM_0003',
}

export const CaloricObj: ProjectObj = {
    title: 'Caloric - Diet Tracker',
    description: 'Your go-to diet tracker for weight and health goals. Easy-to-use, dark mode, multilingual, offline support. Achieve your best self effortlessly. Start now',
    image: 'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fcaloric_preview.png?alt=media&token=64b2ad7e-fa52-4043-af56-b32c5d8a2989',
    overview: 'Caloric: Your essential diet companion for achieving weight and health goals. This user-friendly app simplifies food tracking with an intuitive design. Monitor weight, set goals, and track progress effortlessly. Activate dark mode for stylish low-light use. Easily log food, view calorie consumption, and track vital nutrients. Caloric supports 7 languages, ensuring global accessibility. No internet? No problem. Track offline anywhere, anytime. Download Caloric now and take control of your health journey!',
    features: [`Caloric stands out with a myriad of features tailored to streamline and enhance your diet-tracking experience. The app's intuitive design and user-friendly interface make it a breeze to monitor food intake and progress towards your health goals. A robust weight-tracking feature enables you to set and pursue your target weight seamlessly. Embrace the stylish and comfortable dark mode for low-light conditions with a simple tap. Caloric excels in food tracking, calculating calorie consumption, and detailing essential nutritional information such as protein, carbohydrates, and fat, facilitating informed dietary choices. Language diversity is a key strength, as Caloric supports seven languages, ensuring accessibility for users around the globe. Moreover, the app functions offline, allowing you to track your diet and goals without the need for an internet connection. With its comprehensive suite of features, Caloric empowers users to take control of their diet and embark on a journey towards a healthier lifestyle.`],
    devImages: [
        'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fcaloric_promotional.png?alt=media&token=17e0aeca-f86a-41be-9af4-2c12c11a1bbe'
    ],
    technologies: ['React - Native', 'React', 'Firebase Analytics'],
    specDescription: 'The source code is readily accessible for Android users. However, for those seeking the iOS version, customization of the source code and its packages is necessary to ensure optimal functionality on iOS devices. Additionally, the app must be built on an Apple computer, a prerequisite applicable to all iOS applications.',
    specifications: [
        {
            label: 'React-Native Version',
            value: '0.72.3'
        },
        {
            label: 'React',
            value: '18.2.0'
        },
        {
            label: 'Compatible Mobile OS',
            value: 'Android only'
        },
        {
            label: 'Orientation',
            value: 'Portrait only'
        },
    ],
    projectId: 'caloric',
    appId: 'AM_0002',
}

const projectArray = [
    FlowObj,
    MiraxObj,
    CaloricObj
]

export default projectArray;
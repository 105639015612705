import styled from "styled-components";
import BasePageContainer from "../../components/BasePageContainer";
import Header from "../../components/Header";
import FlexColumn from "../../components/FlexColumn";
import CustomText from "../../components/CustomText";
import TextBox from "../../components/TextBox";
import Button from "../../components/Button";
import { useState } from "react";
import Link from "../../components/Link";
import { sendMessage } from "../../firebase";
import { fadeIn } from "../../styles";

const Container = styled(BasePageContainer)`
    background: #111;
    height: 100vh;
    width: 100vw;
`

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards;
`

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 68vh;
    aspect-ratio: 1 / 1;
    background: #13151B;
    border-radius: 1vh;
    padding: 3vw 3vw 1.5vw 3vw;

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        width: calc(62vh + 5vw);
    }
      
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: calc(62vh + 8vw);
    }
      
    @media only screen and (max-width: 767px) {
        width: 90vw;
        aspect-ratio: 1 / 1.618;
        padding: 7%;
    }
`

const Contact = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleMessage = async () => {
        const response = await sendMessage(email, message);
        if (response) {
            setEmail('');
            setMessage('');
        }
    }

    return (
        <Container>
            <Header />
            <Wrapper>
                <ContactContainer>
                    <FlexColumn>
                        <CustomText fontSize='1.25em' fontWeight='500'>Contact</CustomText>
                        <CustomText fontSize='0.875em' fontWeight='300' color='rgba(255, 255, 255, 0.7)' marginTop='0.75vh'>We will respond as soon as possible</CustomText>

                        <CustomText fontSize='0.95em' fontWeight='300' color='rgba(255, 255, 255, 0.9)' marginTop='4vh'>Email address</CustomText>
                        <TextBox value={email} onChange={(event) => { setEmail(event.target.value) }} type='email-address' placeholder='johnsmith@gmail.com' />
                        <CustomText fontSize='0.95em' fontWeight='300' color='rgba(255, 255, 255, 0.9)' marginTop='3vh'>Message</CustomText>
                        <TextBox value={message} onChange={(event) => { setMessage(event.target.value) }} type='message' placeholder='Leave us a message' />
                    </FlexColumn>

                    <FlexColumn>
                        <Button onClick={() => { handleMessage() }}>Send</Button>
                        <CustomText textAlign='center' fontSize='0.875em' fontWeight='300' color='rgba(255, 255, 255, 0.9)' marginTop='1.5vw'>You can email us directly at <Link href='mailto:aminosdevelopments@gmail.com'>aminosdevelopments@gmail.com</Link></CustomText>
                    </FlexColumn>

                </ContactContainer>
            </Wrapper>
        </Container>
    )
}

export default Contact;
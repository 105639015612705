import { ProjectObj } from "./types/project";

export const handlePurchase = async (appId: ProjectObj['appId']) => {
    try {
        const response = await fetch('https://aminosdev.aminosdevelopments.workers.dev/create-checkout-session', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                items: [{ id: appId, quantity: 1 }]
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        // Process the result as needed
        if (result) {
            window.location.href = result.url
        }

    } catch (error) {
        console.error('Error during POST request:', error);
    }
};
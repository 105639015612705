import styled from "styled-components";
import BasePageContainer from "../../components/BasePageContainer";
import Header from "../../components/Header";
import CustomText from "../../components/CustomText";
import FlexRow from "../../components/FlexRow";
import { GradientHeader } from "../../styles";
import FlexColumn from "../../components/FlexColumn";

const Container = styled(BasePageContainer)`
    min-height: 100vh;
    width: 100vw;
    padding: 10% 22% 0 22%;
`

const Link = styled.a`
    font-weight: 400;
    color: #FFF;
    font-size: 1.1em;
    margin-top: 1vh;
`

const About = () => {
    return (
        <div>
            <Header title="Aminos.Dev.About" />
            <Container>
                <GradientHeader style={{ textAlign: 'center' }}>About Me</GradientHeader>
                <CustomText marginTop="20px" lineHeight="150%" fontSize="1em" fontWeight="300" color="rgba(255, 255, 255, 0.8)">
                    Greetings! I'm Patrick, a seasoned React & React-Native developer equipped with a comprehensive understanding of JavaScript, TypeScript, HTML, and a myriad of other programming languages. While my primary expertise lies in design, I find equal gratification in delving into the intricacies of back-end development. Additionally, my passion extends to exploring diverse programming languages, including C++, through engaging in exciting side projects.</CustomText>
                <CustomText marginTop="20px" lineHeight="150%" fontSize="1em" fontWeight="300" color="rgba(255, 255, 255, 0.8)">For the past three years, I've dedicated myself to crafting compelling mobile applications and websites, honing my skills through diverse experiences across a spectrum of project types. Whether collaborating within small, dynamic teams or working independently, I've navigated the intricacies of development with precision and creativity. My journey reflects a continuous commitment to growth and innovation, ensuring that each project, regardless of scale, receives the meticulous attention and expertise it deserves.</CustomText>
                <FlexColumn style={{ marginTop: '40px' }}>
                    <Link href="https://github.com/AminosRB26">My Github</Link>
                    <Link href="mailto:aminosdevelopments@gmail.com">Email</Link>
                </FlexColumn>
            </Container>
        </div>
    )
}

export default About;
// AuthContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => setUser(user));
        // Clean up the subscription when the component unmounts
        return () => unsubscribe();
    }, [])

    return (<AuthContext.Provider value={user} > {children} </AuthContext.Provider>)
}

export const useAuth = () => {
    return useContext(AuthContext);
};

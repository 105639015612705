import styled from "styled-components";
import BasePageContainer from "../../components/BasePageContainer";
import CustomText from "../../components/CustomText";
import FlexColumn from "../../components/FlexColumn";
import FlexRow from "../../components/FlexRow";
import { Bridge3D, MobileDev, WebDev } from "./svgs";
import { fadeIn } from "../../styles";

const Container = styled(BasePageContainer)`
    background: #111;
    min-height: 100vh;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    padding: 10% 22% 7% 22%;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards;

    @media (min-width: 1024px) and (max-width: 1439px) {
        padding: 7% 0 3% 0;
    }

    @media (max-width: 767px) {
        padding: 15% 0 3% 0;
    }
`

const SkillWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    aspect-ratio: 84 / 73;
    height: 30vh;
    background: #13151B;
    border-radius: 8px;
    padding: 32px 32px 48px 32px;
`

const Circle = styled.div`
    height: 8vh;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    background: rgba(125, 113, 234, 0.15);
    border-radius: 50px;
`

const RenderSkill = ({
    title = '',
    description = '',
    icon = 'design'
}) => {
    return (
        <SkillWrapper>
            <Circle>
                {
                    icon === 'design'
                        ? <Bridge3D />
                        : icon === 'web'
                            ? <WebDev />
                            : <MobileDev />
                }
            </Circle>
            <FlexColumn>
                <CustomText textAlign="center" fontWeight="500" fontSize="1.25em">{title}</CustomText>
                <CustomText marginTop="12px" textAlign="center" fontWeight="400" fontSize="0.85em" lineHeight="150%" color="rgba(255, 255, 255, 0.7)">{description}</CustomText>
            </FlexColumn>
        </SkillWrapper>
    )
}

const Proficiencies = () => {
    return (
        <Container>
            <FlexColumn>
                <CustomText textAlign="center" fontWeight="600" fontSize="4.25em">Proficiencies</CustomText>
                <CustomText textAlign="center" fontWeight="400" fontSize="1.1em" lineHeight="150%" color="rgba(255, 255, 255, 0.7)">My Range of Skills — A Detailed Showcase of What I Excel In, Created with Care, Driven by Passion, and Proven by Experience: A Clear Picture of My Abilities, a Blend of Skills, and Evidence of My Depth of Expertise.</CustomText>
            </FlexColumn>

            <FlexRow $gap="32px">
                <RenderSkill icon="design" title="UI UX Design" description="Infusing creativity and empathy into every pixel, my designs not only captivate visually but orchestrate intuitive journeys" />
                <RenderSkill icon="web" title="Web Development" description="Crafting seamless digital experiences with precision and innovation in web development" />
                <RenderSkill icon="mobile" title="Mobile Development" description="Creating cutting-edge mobile apps with innovative solutions for an intuitive and dynamic user experience." />
            </FlexRow>

        </Container>
    )
}

export default Proficiencies;
import styled from "styled-components";

const TextBox = styled.input`
    height: 58px;
    border-radius: 0.75vh;
    background: transparent;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.2);
    padding: 0 1.25vw 0 1.25vw;
    margin-top: 1vh;
    color: #FFF;
    outline: none;
    letter-spacing: 0.5px;

    &:focus {
        outline: 2px solid #7D71EA;
    }
`

export default TextBox;
import styled from "styled-components";
import CustomText from "../CustomText";
import FlexRow from "../FlexRow";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    background: #111;
    width: 100vw;
    padding: 5vh 7% 5vh 7%;
`

const FlexColumn = styled.div<{ gap?: string; alignItems?: string, $margintop?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap || 'none'};
    align-items: ${props => props.alignItems || 'none'};
    margin-top: ${props => props.$margintop || 'none'};
`

const Link = styled.a`
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.95em;
    margin-top: 1vh;
`

const Footer = () => {
    return (
        <Container>
            <FlexColumn>
                <CustomText fontSize="1.125em" fontWeight="500">Aminos Developments</CustomText>
                <CustomText fontSize="0.875em" fontWeight="300" color='rgba(255, 255, 255, 0.7)' marginTop="1vh">© 2023 Aminos Developments, All rights reserved</CustomText>
            </FlexColumn>

            <FlexColumn>
                <FlexRow $justifycontent="space-between" $alignitems="flex-start" $width="30vw">
                    <FlexColumn>
                        <CustomText fontSize="1em" fontWeight="500">Products</CustomText>
                        <Link href="/project/epoxy">Epoxy - Habit Tracker</Link>
                        <Link href="/project/caloric">Caloric - Diet Tracker</Link>
                        <Link href="/project/mirax">Mirax - Meditation</Link>
                    </FlexColumn>
                    <FlexColumn>
                        <CustomText fontSize="1em" fontWeight="500">Contact</CustomText>
                        <Link href="mailto:aminosdevelopments@gmail.com">Email</Link>
                        <Link href="https://github.com/AminosRB26">Github</Link>
                        <Link href="https://twitter.com/MXNYstudios">Twitter</Link>
                    </FlexColumn>
                </FlexRow>
                <FlexRow $justifycontent="space-between" $alignitems="flex-start" $width="30vw" $margin="2vh 0 0 0">
                    <FlexColumn $margintop="2vh">
                        <CustomText fontSize="1em" fontWeight="500">Info</CustomText>
                        <Link href="/privacy">Privacy Policy</Link>
                        <Link href="/terms">Terms & Conditions</Link>
                    </FlexColumn>
                    <FlexColumn $margintop="2vh">
                        <CustomText fontSize="1em" fontWeight="500">Help</CustomText>
                        <Link>Help</Link>
                        <Link>Tutorials</Link>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        </Container>
    )
}

export default Footer;
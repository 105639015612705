import React from 'react'
import styled from 'styled-components';
import CustomText from '../CustomText';
import { ProjectObj } from '../../types/project';
import FlexRow from '../FlexRow';

interface Props extends Partial<ProjectObj> {
    onPurchase?: (() => void);
    onView?: (() => void);
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 26vw;

    @media (max-width: 767px) {
        width: 90vw;
    }
`

const Image = styled.img`
    width: 100%;
    aspect-ratio: 16 / 9;
    box-shadow: 0px 4px 38px 8px rgba(150, 140, 240, 0.25);
    border-radius: 1vh;
`

const Button = styled.button<{ $background?: string }>`
    height: 40px;
    border-radius: 12px;
    background: ${props => props.$background || 'rgba(255, 255, 255, 0.1)'};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 0.875em;
    font-weight: 400;
    color: #FFF;
    aspect-ratio: 18 / 6;
    outline: none;
    border: none;
`

const ProjectPreview: React.FC<Props> = ({
    image = undefined,
    title = '',
    description = '',
    onPurchase = () => { },
    onView = () => { },
}) => {
    return (
        <Container>
            <Image src={image} />
            <CustomText fontSize='1.5em' fontWeight='500' marginTop='2vh'>{title}</CustomText>
            <CustomText fontSize='0.95em' color='rgba(255, 255, 255, 0.6)' marginTop='1vh' fontWeight='300' lineHeight='140%'>{description}</CustomText>
            <FlexRow $margin='2vh 0 0 0' $gap='14px'>
                <Button onClick={() => { onView() }}>View</Button>
                {/* <Button onClick={() => { onPurchase() }}>Purchase</Button> */}
            </FlexRow>
        </Container>
    )
}

export default ProjectPreview;
import styled from 'styled-components';
import Header from '../../components/Header';
import Button from '../../components/Button';
import WebsitePricing from '../WebsitePricing';
import Footer from '../../components/Footer';
import FlexColumn from '../../components/FlexColumn';
import CustomText from '../../components/CustomText';
import FlexRow from '../../components/FlexRow';
import { useEffect } from 'react';
import { fadeIn } from '../../styles';
import Proficiencies from '../Proficiencies';
import { createBlankProject } from '../../firebase';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: #111;
  padding: 20% 0 0 0;
  align-items: center;

  @media (min-width: 1024px) and (max-width: 1439px) {
    padding: 15% 0 0 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const PromotionalWrapper = styled(Wrapper)`
  flex-direction: row;
  background: #7D71EA;
  padding: 0 7% 0 7%;
  justify-content: space-between;
`

const Subheader = styled.h3`
  font-size: 1.25em;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  line-height: 38px
`;

const Image = styled.img`
  height: 90%;
`

const PlayImage = styled.img`
  width: 220px;
  margin-left: -12px;
`

const GradientHeader = styled.span`
  font-size: 5.675em;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -2px;
  background: linear-gradient(104deg, #009FFF 0%, #EC2F4B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;

  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    font-size: 5em;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 4.5em;
  }
  
  @media only screen and (max-width: 767px) {
    font-size: 3em;
  }
`

const LandingWrapper = styled(Wrapper)`
  padding: 0 0 0 7%;
  justify-content: center;

  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;

  // @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    
  // }
  // @media only screen and (min-width: 768px) and (max-width: 1023px) {
  //   height: 50vh;
  // }
  @media only screen and (max-width: 767px) {
    padding: calc(10vh + 2vh) 5% 0 5%;
  }
`

const LandingImage = styled.img`
  height: 64vh;

  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 60vh
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    height: 50vh;
  }
  @media only screen and (max-width: 767px) {
    visibility: hidden;
  }
`

const Link = styled.a`
  color: #FFF;
  margin-left: 2vw;
  font-weight: 400;
`

const Home = () => {

  return (
    <Container>
      <Header />

      <LandingWrapper>
        <FlexRow>
          <FlexColumn $padding='0 12% 0 0'>
            <GradientHeader>Innovate<br />your design<br />experience</GradientHeader>
            <CustomText marginTop='3vh' lineHeight='150%' color='rgba(255, 255, 255, 0.7)'>Discover a world of mobile innovation. From sleek designs to powerful functionality, our apps redefine user experiences. Embrace seamless connectivity and unlock the potential of cutting-edge technology with us.</CustomText>
            <FlexRow $margin='2vh 0 0 0'>
              <Button onClick={() => {
                window.location.href = '/contact'
              }} $height='5vh' $background='rgba(255, 255, 255, 0.1)'>Start now</Button>
              {/* <Button onClick={() => { window.location.href = '/pricing' }} $height='5vh' $background='rgba(255, 255, 255, 0.1)'>Start now</Button> */}
              <Link href='mailto:aminoscorporation@gmail.com'>Contact us</Link>
            </FlexRow>
          </FlexColumn>
          <LandingImage src={'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Flanding_preview.png?alt=media&token=7ebbe0d5-4985-4c94-a6db-b509bfb6c0c9'} />
        </FlexRow>
      </LandingWrapper>

      <PromotionalWrapper>
        <Column>
          <CustomText fontSize='3.5em' fontWeight='600'>Epoxy<br></br>Habit Tracker</CustomText>
          <Subheader>Habit tracking app developed for Android in React-Native Currently available on Google Play Store</Subheader>
          <a href='https://play.google.com/store/apps/details?id=com.epoxy&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <PlayImage alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
          </a>
        </Column>
        <Image src={'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fepoxy_rotated_preview.png?alt=media&token=0152d0de-d2fa-4f04-b114-a99f60f7c1be'} />
      </PromotionalWrapper>

      {/* <WebsitePricing /> */}
      <Proficiencies />

      <PromotionalWrapper>
        <Column>
          <CustomText fontSize='3.5em' fontWeight='600'>Mirax<br></br>Meditation</CustomText>
          <Subheader>Cultivate calmness with guided sessions, serene tunes, and tranquil ambiance. Your journey to mindfulness begins here.</Subheader>
          <Button onClick={() => { window.location.href = '/project/mirax' }} $background='rgba(255, 255, 255, 0.25)' $margintop='2vh' >View</Button>
        </Column>
        <Image src={'https://firebasestorage.googleapis.com/v0/b/epoxy-31398.appspot.com/o/website%2Fimages%2Fmirax_rotated_preview.png?alt=media&token=5dfb60ce-8b9d-4744-9395-46870c732beb'} />
      </PromotionalWrapper>
      <Footer />
    </Container>
  )
}

export default Home;
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Timestamp, collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import validator from "validator";
import projectArray from "./data/projects";
import { ProjectObj } from "./types/project";
import { v4 as uuid } from "uuid";

const firebaseConfig = {
    apiKey: "AIzaSyBFeeM_nF-3bey10NtuDmIzgNJC7zxGJxA",
    authDomain: "epoxy-31398.firebaseapp.com",
    projectId: "epoxy-31398",
    storageBucket: "epoxy-31398.appspot.com",
    messagingSenderId: "1079651511262",
    appId: "1:1079651511262:web:a01c6c45951865c4254b4f",
    measurementId: "G-T392331G0E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const registerWithEmailAndPassword = async (email: string, password: string): Promise<boolean> => {
    try {
        if (!validator.isEmail(email)) {
            console.log("Email is not valid", email);
            return false;
        }
        if (!validator.isStrongPassword(password, { minSymbols: 0, })) {
            console.log("Password not strong enough", "Must contain atleast 1 uppercase, lowercase & number and be atleast 8 characters long");
            return false;
        }

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        const userDocRef = doc(db, 'website_users', user.uid);

        await setDoc(userDocRef, {
            email,
            password,
            timestamp: Timestamp.now(),
            id: user.uid
        });

        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

// Function for user login
export const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('User logged in:', user);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const getProjects = async (): Promise<ProjectObj[] | null> => {
    try {
        const querySnapshot = await getDocs(collection(db, 'projects'));

        return querySnapshot.docs.map(event => event.data()) as ProjectObj[];
    } catch (e) {
        console.error(e);
        return null;
    }
}

// Dev function for uploading project data to Database
export const uploadProjectData = async () => {
    const arr = projectArray;

    arr.forEach(async (project) => {
        const docRef = doc(db, 'projects', project.projectId);

        await setDoc(docRef, { ...project });
    });
}

export const sendMessage = async (email: string, message: string) => {
    try {

        if (!validator.isEmail(email)) {
            console.log("Invalid email", "Email is not in valid format");
            return false;
        }

        const messageId = uuid();

        const userDocRef = doc(db, 'messages', messageId);

        await setDoc(userDocRef, {
            email,
            message,
            messageId
        });

        return true;
    } catch (e) {
        console.error(e)
        return false;
    }
}

export const createBlankProject = async () => {
    try {
        const projectId = uuid();

        const projectRef = doc(db, 'projects', projectId);

        await setDoc(projectRef, {
            title: '',
            description: '',
            image: '',
            overview: '',
            features: [],
            technologies: [],
            devImages: [],
            specDescription: '',
            specifications: [],
            projectId: '',
            appId: ''
        } as ProjectObj)
    } catch (e) {
        console.error(e);
    }
}
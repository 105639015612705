import React from 'react'
import styled from 'styled-components';
import CustomText from '../CustomText';
import FlexColumn from '../FlexColumn';

interface Props extends PricingOptions {
  onButtonPress?: (() => void);
}

const PricingWrapper = styled.div<{ glow?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #13151B;
    width: 31vh;
    aspect-ratio: 31/44;
    border-radius: 4px;
    padding: 3% 3% 3% 3%;
    box-shadow: ${props => props.glow ? '0px 4px 20px 4px rgba(125, 113, 234, 0.15)' : 'none'};

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
      width: calc(31vh + 5vw);
    }
    
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      width: calc(31vh + 8vw);
    }
    
    @media only screen and (max-width: 767px) {
      width: 90vw;
      aspect-ratio: 30 / 32.5;
      padding: 7%;
    }
`

const PricingButton = styled.button<{ premium?: boolean; }>`
  background: ${props => props.premium ? '#7D71EA' : 'transparent'};
  border-width: ${props => props.premium ? '0px' : '2px'};
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: #FFF;
  font-weight: 400;
  font-size: 0.875em;
  height: 5.2vh;
  margin-top: 12px;

  @media (min-width: 1024px) and (max-width: 1439px) {
      height: 7vh;
  }
`

const PricingOption: React.FC<Props> = ({
  packageType = 'Standard',
  price = '$249',
  description = 'Simple website to showcase your business',
  features = [],
  premium = false,
  onButtonPress
}) => {
  return (
    <PricingWrapper glow={premium}>
      <FlexColumn>
        <CustomText fontSize='1em' fontWeight='300' color='#FFF'>{packageType}</CustomText>
        <CustomText fontSize='2.025em' fontWeight='600' color='#FFF' marginTop='0.7vh'>{price}</CustomText>
        <CustomText fontSize='0.875em' fontWeight='300' color='rgba(255, 255, 255, 0.5)' lineHeight='150%' marginTop='0.3vh'>{description}</CustomText>
      </FlexColumn>
      <FlexColumn gap='1.2vh'>
        {features.map(str => <CustomText fontWeight='400' fontSize='0.875em'>{str}</CustomText>)}
        <PricingButton premium={premium} onClick={() => { onButtonPress && onButtonPress() }}>Continue</PricingButton>
      </FlexColumn>
    </PricingWrapper>
  )
}

export default PricingOption;
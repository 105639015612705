import styled from "styled-components";

const FlexRow = styled.div<{ $justifycontent?: string; $alignitems?: string; $width?: string; $margin?: string; $gap?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.$justifycontent || 'auto'};
    align-items: ${props => props.$alignitems || 'center'};
    width: ${props => props.$width || 'none'};
    margin: ${props => props.$margin || 'none'};
    gap: ${props => props.$gap || 'none'};
`

export default FlexRow;
import styled from 'styled-components';
import BasePageContainer from '../../components/BasePageContainer';
import Header from '../../components/Header';
import CustomText from '../../components/CustomText';
import Button from '../../components/Button';
import { registerWithEmailAndPassword } from '../../firebase';
import { useState } from 'react';
import FlexColumn from '../../components/FlexColumn';
import TextBox from '../../components/TextBox';
import Link from '../../components/Link';
import { fadeIn } from '../../styles';

const Container = styled(BasePageContainer)`
    background: #111;
    height: 100vh;
    width: 100vw;
`

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards;
`

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 31vw;
    aspect-ratio: 1 / 1;
    background: #13151B;
    border-radius: 1vh;
    padding: 3vw 3vw 1.5vw 3vw;

    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        width: calc(62vh + 5vw);
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: calc(62vh + 8vw);
    }

    @media only screen and (max-width: 767px) {
        width: 90vw;
        aspect-ratio: 1 / 1.618;
        padding: 7%;
    }
`

const Signup = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleRegister = async () => {
        const status = await registerWithEmailAndPassword(email, password);
        if (status) {
            // Account has been created and authenticated
            // Continue to dashboard
            window.location.href = '/';
        }
    }

    return (
        <Container>
            <Header />
            <Wrapper>
                <SignupContainer>
                    <FlexColumn>
                        <CustomText fontSize='1.25em' fontWeight='500'>Create An Account</CustomText>
                        <CustomText fontSize='0.875em' fontWeight='300' color='rgba(255, 255, 255, 0.7)' marginTop='0.75vh'>Create a new account with an email and password</CustomText>

                        <CustomText fontSize='0.95em' fontWeight='300' color='rgba(255, 255, 255, 0.9)' marginTop='4vh'>Email address</CustomText>
                        <TextBox value={email} onChange={(event) => { setEmail(event.target.value) }} type='email-address' placeholder='johnsmith@gmail.com' />
                        <CustomText fontSize='0.95em' fontWeight='300' color='rgba(255, 255, 255, 0.9)' marginTop='3vh'>Password</CustomText>
                        <TextBox value={password} onChange={(event) => { setPassword(event.target.value) }} type='password' placeholder='Choose a secure password' />
                    </FlexColumn>

                    <FlexColumn>
                        <Button onClick={() => { handleRegister() }}>Continue</Button>
                        <CustomText textAlign='center' fontSize='0.875em' fontWeight='300' color='rgba(255, 255, 255, 0.9)' marginTop='1.5vw'>Already have an account? <Link href='/login'>Login</Link></CustomText>
                    </FlexColumn>

                </SignupContainer>
            </Wrapper>
        </Container>
    )
}

export default Signup;
import styled from "styled-components";

const FlexColumn = styled.div<{
    gap?: string;
    alignItems?: string;
    $margintop?: string;
    $padding?: string;
}>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap || 'none'};
    align-items: ${props => props.alignItems || 'none'};
    margin-top: ${props => props.$margintop || 'none'};
    padding: ${props => props.$padding || 'none'};
`

export default FlexColumn;